import dayjs from "dayjs";
import ShortIdGen from "short-unique-id";

const shortIdGen = new ShortIdGen({ length: 5 });

// IndexedDB Utilities
export const openIndexedDB = (eventKey) =>
  new Promise((resolve, reject) => {
    const version = parseInt(localStorage.getItem("dbVersion"), 10);
    const request = indexedDB.open("DelegateDB", version);
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(eventKey)) {
        const store = db.createObjectStore(eventKey, { keyPath: "id" });
        store.createIndex("synced", "synced", { unique: false });
        store.createIndex("updatedAt", "updatedAt", { unique: false });
        store.createIndex("shortId", "shortId", { unique: false });
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });

export const getUnsyncedData = async (eventKey) => {
  const db = await openIndexedDB(eventKey);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(eventKey, "readonly");
    const store = transaction.objectStore(eventKey);
    const unsyncedData = [];

    const cursorRequest = store.openCursor(); // Open a cursor for all records

    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const record = cursor.value;
        if (record.synced === false) {
          // Check for unsynced records
          unsyncedData.push({
            id: record.id,
            registrationsScans: record.registrationsScans,
            delegateType: record.delegateType,
            status: record.status,
            shortId: record.shortId,
            updatedAt: record.updatedAt,
          });
        }
        cursor.continue();
      } else {
        resolve(unsyncedData);
      }
    };
    cursorRequest.onerror = () => reject(cursorRequest.error);
  });
};

export const markAsSynced = async (delegates, eventKey) => {
  const db = await openIndexedDB(eventKey);
  const transaction = db.transaction(eventKey, "readwrite");
  const store = transaction.objectStore(eventKey);

  delegates.forEach((delegate) => {
    store.put({ ...delegate, synced: true });
  });
};

export const loadAllDelegates = async (eventKey, search = "") => {
  const db = await openIndexedDB(eventKey);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(eventKey, "readonly");
    const store = transaction.objectStore(eventKey);
    const allDelegates = [];

    store.openCursor().onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const record = cursor.value;

        if (search) {
          const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
          const company = record.company?.toLowerCase() || "";
          const email = record.email?.toLowerCase() || "";
          const searchTerm = search.toLowerCase();

          // Match against fullname, company, or email
          if (
            fullName.includes(searchTerm) ||
            company.includes(searchTerm) ||
            email.includes(searchTerm)
          ) {
            allDelegates.push(record);
          }
        } else {
          allDelegates.push(record); // Add all records if no search term
        }

        cursor.continue();
      } else {
        resolve(allDelegates);
      }
    };

    store.openCursor().onerror = (error) => reject(error);
  });
};

export const mergeServerData = async (latestData, eventKey) => {
  const db = await openIndexedDB(eventKey);
  const transaction = db.transaction(eventKey, "readwrite");
  const store = transaction.objectStore(eventKey);

  latestData.forEach((serverRecord) => {
    const request = store.get(serverRecord.id);

    request.onsuccess = (event) => {
      const localRecord = event.target.result;

      if (!localRecord) {
        // New delegate, add it locally
        store.put({ ...serverRecord, synced: true });
      } else if (dayjs(serverRecord.updatedAt) > dayjs(localRecord.updatedAt)) {
        // Conflict resolution: server data is newer
        store.put({ ...serverRecord, synced: true });
      } else if (localRecord.scanned && !localRecord.synced) {
        // If local record is unsynced and scanned, keep it for next sync
        store.put({ ...localRecord });
      }
    };
  });
};

export const getDelegateById = async (id, eventKey) => {
  const db = await openIndexedDB(eventKey);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(eventKey, "readonly");
    const store = transaction.objectStore(eventKey);
    const request = store.get(id);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
    request.onerror = () => reject(request.error);
  });
};

export const getDelegateByShortId = async (id, eventKey) => {
  const db = await openIndexedDB(eventKey);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(eventKey, "readonly");
    const store = transaction.objectStore(eventKey);
    const index = store.index("shortId"); // Use the `shortId` index
    const request = index.get(id);

    request.onsuccess = (event) => {
      resolve(event.target.result); // Return the delegate if found
    };
    request.onerror = () => reject(request.error);
  });
};

export const markDelegate = async ({ id, pass, confirmed, eventId }) => {
  const eventKey = `${eventId}_${pass}`;
  const db = await openIndexedDB(eventKey);
  const getDelegate = id.length === 5 ? getDelegateByShortId : getDelegateById;
  const delegate = await getDelegate(id, eventKey);

  if (delegate) {
    return new Promise((resolve, reject) => {
      if (!delegate.passes?.includes(pass)) {
        throw new Error(`Unauthorised for ${pass}`);
      }
      const hasPass = delegate.registrationsScans.some((scan) => scan.pass === pass);
      if (hasPass) {
        resolve({ data: { rows: [delegate] }, scanned: true });
        return;
      }
      if (delegate.status === "LEAD" && !confirmed) {
        resolve({ data: { rows: [delegate] }, scanned: false, notConfirmed: true });
        return;
      }

      const params = {
        createdAt: dayjs().toISOString(),
        pass,
      };

      const updatedDelegate = {
        ...delegate,
        status: "CONVERTED",
        registrationsScans: [...delegate.registrationsScans, params],
        updatedAt: dayjs().toISOString(),
        synced: false,
      };
      if (!delegate.shortId) {
        const shortKey = shortIdGen.rnd();
        updatedDelegate.shortId = shortKey;
      }

      const transaction = db.transaction(eventKey, "readwrite");
      const store = transaction.objectStore(eventKey);

      store.put(updatedDelegate);

      transaction.oncomplete = () =>
        resolve({
          data: {
            rows: [updatedDelegate],
            count: 1,
          },
          scanned: false,
        });
      transaction.onerror = () => reject(transaction.error);
    });
  }
  throw new Error(`Delegate with id ${id} not found.`);
};
