/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Loading, RefreshButton, useDataProvider, useList, useNotify } from "react-admin";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardGridWidget from "../../components/CardWidget";
import { callApi } from "../../dataprovider/miscApis";
import LoadingGridList from "../../components/LoadingWidget";

export default ({ details, setTab }) => {
  const [counts, setCounts] = useState([]);
  const [isLoading, setLoading] = useState({});
  const notify = useNotify();

  const { pass } = details || {};

  const getData = async () => {
    setLoading(true);
    await callApi(`getLiveStats?pass=${pass}`, {}, "GET")
      .then(({ data }) => {
        setCounts(data?.counts || []);
      })
      .catch(() => {
        notify("Error loading data", { type: "warning" });
      });
    setLoading(false);
  };

  useEffect(() => {
    if (pass) {
      getData();
    }
  }, [pass]);

  const styles = {
    grid: {
      marginTop: 2,
    },
  };

  if (isLoading) {
    return <LoadingGridList grid={3} />;
  }

  if (counts.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "40vh",
        }}
      >
        <Typography variant="h4" style={{ color: "black" }} gutterBottom>
          No Data Yet!
        </Typography>
        <Button
          variant="contained"
          component="label"
          size="large"
          onClick={() => {
            setTab("qr");
          }}
        >
          Start Scanning
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={1} alignItems="center" sx={styles.grid} justifyContent="flex-start">
        {counts.map((c) => (
          <CardGridWidget
            grid={3}
            value={c.count}
            title={`${c.type || "DELEGATE"}${c.count > 1 ? "S" : ""}`}
            key={c.type}
          />
        ))}
      </Grid>
    </>
  );
};
