import React from "react";
import { Button } from "react-admin";
import Typography from "@mui/material/Typography";
import PrintIcon from "@mui/icons-material/Print";
import Grid from "@mui/material/Grid";

const InfoCard = ({ speaker, hidePrint, printLabel }) => {
  const LabelValue = ({ label, value, bigValue }) => (
    <>
      <Typography variant="body2" style={{ color: "#9e9e9e" }}>
        {label}
      </Typography>
      <Typography variant={bigValue ? "h6" : "body1"} style={{ wordWrap: "break-word" }}>
        {value}
      </Typography>
    </>
  );

  return (
    <div style={{ padding: "1.5rem 0" }}>
      <Grid container spacing={2}>
        <Grid item xs={8} style={{ marginTop: 4 }}>
          {speaker.image ? (
            <img
              src={speaker.image}
              width="150px"
              alt={speaker.name}
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <img
              src={`https://ui-avatars.com/api/?background=1a237e&color=fff&name=${speaker.name}`}
              width="60px"
              alt={speaker.name}
            />
          )}
        </Grid>
        {!hidePrint && (
          <Grid item xs={4} spacing={2} sx={{ textAlign: "right" }}>
            <Button variant="outlined" size="large" onClick={() => printLabel(speaker)} label="Print">
              <PrintIcon style={{ marginRight: 2 }} />
            </Button>
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: 4 }}>
          <LabelValue label="" value={`${speaker.name}`} bigValue />
          <LabelValue label="" value={`${speaker.title}, ${speaker.company}, ${speaker.country}`} />
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoCard;
