/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import { Button, ListContextProvider, SimpleList, useList, useNotify } from "react-admin";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import TextInput from "@mui/material/TextField";
import PrintIcon from "@mui/icons-material/Print";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import { Card, Grid } from "@mui/material";
import debounce from "lodash.debounce";
import { loadAllDelegates } from "./indexDbUtils";

export default ({ printLabel, onConfirm, eventKey }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const notify = useNotify();

  const getData = (val) =>
    loadAllDelegates(eventKey, val)
      .then((values) => {
        setData(values);
      })
      .catch((error) => {
        notify(error);
      });
  const debouncedSave = useCallback(
    debounce((val) => getData(val), 1000),
    []
  );

  const handleChange = (e) => {
    setSearch(e.target.value);
    debouncedSave(e.target.value);
  };

  const listContext = useList({ data });

  return (
    <Grid container spacing={2} sx={{ height: "100%", padding: "0 1rem" }}>
      <Grid item lg={8} xs={12} sx={{ textAlign: "center" }}>
        <Card style={{ height: "100%", width: "100%", padding: "1rem" }}>
          <TextInput
            label="Search"
            variant="filled"
            fullWidth
            value={search}
            onChange={handleChange}
          />
          <ListContextProvider value={listContext}>
            <SimpleList
              primaryText={(record) => `${record.firstName} ${record.lastName}`}
              tertiaryText={(record) => (
                <div style={{ textAlign: "right" }}>
                  <Typography variant="h5" color="black">
                    {record.registrationsScans?.length > 0 ? "SCANNED" : record.status}
                  </Typography>

                  <span style={{ fontSize: "10px" }}>Passes</span>
                  <br />
                  {record.passes?.join(", ")}
                  <br />
                  <br />
                  {record.registrationsScans?.length > 0 ? (
                    <Tooltip title=" Print">
                      <Button variant="contained" onClick={() => printLabel(record)} label="Print">
                        <PrintIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Confirm and Print">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={async () => {
                          await onConfirm(record);
                          await getData(search);
                        }}
                        label="Confirm"
                      >
                        <CheckIcon />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
              secondaryText={(record) => (
                <>
                  {record.title}
                  <br />
                  {`${record.company}, ${record.country}`}
                  <br /> <br />
                  <Tooltip title="Click to copy">
                    <Link
                      component="button"
                      onClick={() => {
                        copy(record.email);
                      }}
                      underline="none"
                    >
                      <Typography variant="body2">{record.email}</Typography>
                    </Link>
                  </Tooltip>
                  <br /> <br />
                  {record.delegateType}
                </>
              )}
              rowStyle={() => ({
                border: "1px solid #afafaf",
                borderRadius: "12px",
                marginBottom: "5px",
                padding: "12px",
              })}
              linkType={false}
            />
          </ListContextProvider>
        </Card>
      </Grid>
    </Grid>
  );
};
