import React from "react";
import Fab from "@mui/material/Fab";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { useDelegates } from "./context";

const ReSyncButton = () => {
  const { syncData } = useDelegates();
  return (
    <div style={{ position: "absolute", bottom: "1rem", right: "1rem" }}>
      <Fab color="primary" variant="extended" aria-label="Re-Sync" size="small" onClick={syncData}>
        <CloudSyncIcon sx={{ mr: 1 }} />
        Re-Sync
      </Fab>
    </div>
  );
};

export default ReSyncButton;
