/* eslint-disable no-console */
import React, { createContext, useContext, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { getUnsyncedData, markAsSynced, mergeServerData } from "./indexDbUtils";
import { callApi } from "../../dataprovider/miscApis";

const TIMEOUT = 300000;

// React Context
const DelegateContext = createContext();

export const DelegateProvider = ({ eventKey, details, children }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const getAllDelegates = () =>
    dataProvider.getList("registrations", {
      pagination: { page: 1, perPage: 999 },
      sort: { field: "createdAt", order: "DESC" },
      filter: { pass: details.pass, getAll: true },
    });

  const syncUnsynced = (unSynced) => callApi("registrations/sync", { unSynced }, "POST");

  const syncData = async () => {
    if (!navigator.onLine) {
      notify("Offline. Retrying in 5 mins.", { type: "warning" });
      return;
    }
    console.info("Syncing Now");
    try {
      // Fetch unsynced data
      const unsyncedData = await getUnsyncedData(eventKey);

      if (unsyncedData.length > 0) {
        await syncUnsynced(unsyncedData).catch(() => {
          throw new Error("Sync failed, retrying after 5 mins");
        });

        await markAsSynced(unsyncedData, eventKey);
      }

      // Fetch latest server data
      const latestDataResponse = await getAllDelegates()
        .then(({ data: values }) => values)
        .catch(() => {
          throw new Error("Download failed");
        });

      // // Merge latest server data with IndexedDB
      await mergeServerData(latestDataResponse, eventKey);

      notify("Sync complete.", { type: "success" });
    } catch (error) {
      notify(`Sync failed: ${error.message}. Retrying in 5 mins.`, { type: "warning" });
      setTimeout(() => syncData(), TIMEOUT);
    }
  };

  useEffect(() => {
    let interval;
    if (details.pass) {
      syncData();
      interval = setInterval(() => syncData(), TIMEOUT);
    }
    return () => clearInterval(interval);
  }, [eventKey, notify]);

  return <DelegateContext.Provider value={{ syncData }}>{children}</DelegateContext.Provider>;
};

export const useDelegates = () => useContext(DelegateContext);
